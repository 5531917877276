footer {
  min-height: 200px;
  background: #f1f2f3;
  color: #717070;
  display: flex;
  padding: 2.2% 3%;
  justify-content: space-around;
  /* align-items: center; */
}
.footer_logo_main {
  display: flex;
  /* width: 45%; */
  flex-direction: column;
  /* background-color: antiquewhite; */
}
.footer_logo {
  /* height: 60px;
  width: 60px; */
  overflow: hidden;
}
.footer_logo img {
  height: 90px;
  width: 107px;
  background-size: cover;
  background-repeat: no-repeat;
}
.footer_logo_copyright {
  font-weight: 500;
  font-family: var(--var-font-family);
  margin-top: 10px;
}
/* .footer_icons_small_screen {
  display: none;
} */
.footer_icons {
  display: flex;
  justify-content: space-around;
  color: white;
}
.footer_icons a {
  padding: 0 30px;
  /* color: white; */
  transition: 0.5s ease-in-out;
}
.footer_icons a:hover {
  color: var(--primary-color);
  transform: scale(1.2);
}

.footer_contact_info.links {
  /* margin: 0 4%; */
}
.footer_contact_info {
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  align-self: stretch;
  gap: 3%;
}
.footer_contact_info span,
a {
  margin: 5px 15px;
  /* width: 100px; */
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  /* background-color: aqua; */
}
.nav_link_footer {
  color: #717070;
  text-decoration: none;
  padding: 5px 5px;
  padding-bottom: 3px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  min-width: 90px;
}
.nav_link_footer:hover {
  color: var(--primary-color);
}

.footer_services {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 2%;
}

@media screen and (max-width: 990px) {
  .footer_logo_copyright {
    font-size: 0.85rem;
  }
  .footer_icons a {
    padding: 0 24px;
  }
  .footer_contact_info span,
  a {
    margin: 3px 15px;
    font-size: 0.95rem;
  }
}
@media screen and (max-width: 768px) {
  footer {
    flex-direction: column;
    align-items: normal;
  }
  .small_screen_style {
    display: flex;
    justify-content: space-between;
    margin: 25px 0;
  }
  .footer_logo_copyright {
    font-size: 0.66rem;
    margin-top: 15px;
  }
  .footer_icons {
    display: none;
  }

  .footer_icons_small_screen {
    display: flex;
    /* flex-direction: column; */
  }
  .footer_icons_small_screen a {
    padding: 5px 10px;
    /* color: white; */
  }
  .footer_contact_info.links {
    /* flex-direction: row; */
    justify-content: flex-start;
    margin-bottom: 5%;
  }
  .footer_contact_info {
    /* flex-direction: row-reverse; */
    /* justify-content: flex-end; */
    flex-wrap: wrap;
  }
  .footer_contact_info span,
  a {
    margin: 5px;
    font-size: 0.95rem;
  }
}
@media screen and (max-width: 426px) {
  footer {
    flex-direction: column;
    align-items: normal;
  }
  .small_screen_style {
    display: flex;
    justify-content: space-between;
    margin: 25px 0;
  }
  .footer_logo_copyright {
    font-size: 0.66rem;
    margin-top: 15px;
  }
  .footer_icons {
    display: none;
  }

  .footer_icons_small_screen {
    display: flex;
    /* flex-direction: column; */
  }
  .footer_icons_small_screen a {
    padding: 5px 10px;
    /* color: white; */
  }
  .footer_contact_info {
    /* flex-direction: row-reverse; */
    /* justify-content: flex-end; */
    flex-wrap: wrap;
  }
  .footer_contact_info span,
  a {
    margin: 5px;
    font-size: 0.95rem;
  }
}
@media screen and (max-width: 320px) {
  .small_screen_style {
    display: flex;
    justify-content: space-between;
    margin: 25px 0;
  }
  .footer_logo_main {
    justify-content: space-around;
  }
  .footer_logo_copyright {
    font-size: 0.66rem;
    margin-top: 15px;
  }
  .footer_icons {
    display: none;
  }

  /* .footer_icons_small_screen {
    display: flex;
    flex-direction: column;
  } */
  /* .footer_icons_small_screen a {
    padding: 5px 10px;
    color: white;
  } */
  /* .footer_contact_info {
    flex-direction: row-reverse;
    justify-content: flex-end;
    flex-wrap: wrap;
  } */
  .footer_contact_info span,
  a {
    margin: 5px;
    font-size: 0.95rem;
  }
}
