.blur_contact_form {
  padding: 8% 7%;
  background-color: hsla(0, 0%, 77%, 0.2);
  box-shadow: 26px 30px 33px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(18px);
  border-radius: 10px;
  position: relative;
}
.contact_form_heading {
  padding: 2% 0;
  /* color: rgb(43, 43, 43); */
}
.contact_form_field {
}
.blur_contact_form_field_text {
  font-size: 1.1rem;
  font-weight: 500;
  padding: 1% 0px;
  color: white;
}
.blur_contact_form_field input {
  padding: 10px 10px;
  border: none;
  background-color: hsla(0, 0%, 100%, 0.5);
  outline: none;
  border-radius: 7px;
  margin-bottom: 5%;
  color: white;
  font-weight: 500;
  font-size: 0.9rem;
  width: 98%;
}
.blur_contact_form_field textarea {
  padding: 10px 10px;
  border: none;
  background-color: hsla(0, 0%, 100%, 0.5);
  outline: none;
  border-radius: 7px;
  margin-bottom: 5%;
  color: white;
  font-weight: 500;
  font-size: 0.9rem;
  width: 98%;
}
.blur_contact_form_field input::-webkit-input-placeholder {
  color: white;
}
.blur_contact_form_field textarea::-webkit-input-placeholder {
  color: white;
}
.blur_contact_form_field textarea:focus {
  color: white;
  font-weight: 400;
}
.blur_contact_form_field input:focus {
  /* color: rgb(18, 18, 18); */
  color: white;
  font-weight: 400;
}
.contact_checkbox_container {
  display: flex;
  align-items: flex-start;
}
.contact_checkbox_container input[type="checkbox"] {
  width: 20px;
  height: 20px;
  border-radius: 7px;
  margin-right: 9px;
  font-size: 2rem;
  border: 0.25rem solid green;
  accent-color: var(--primary-color);
}
.blur_contact_checkbox_text {
  margin-top: -2px;
  /* color: rgb(62, 60, 60); */
  color: white;
  font-weight: 400;
  font-size: 1rem;
}
.contact_checkbox_container input[type="checkbox"]:checked {
  background: white;
  background-color: white;
}
.blur_contact_form_button {
  padding: 7px 10px;
  border: 1px solid var(--primary-color);
  width: 98%;
  transition: 0.5s ease-in-out;
  border-radius: 10px;
  margin-top: 1.2rem;
  color: white;
  /* color: var(--primary-color); */
  font-weight: 500;
  /* background-color: transparent; */
  background-color: #43c475;
}
.blur_contact_form_button:hover {
  background-color: var(--primary-color);
  color: white;
  font-weight: 500;
}

@media screen and (min-width: 1600px) {
  .blur_contact_form_field_text {
    font-size: 1.5rem;
  }
  .blur_contact_form_field input {
    font-size: 1.1rem;
    padding: 15px 10px;
  }
  .blur_contact_form_field textarea {
    font-size: 1.1rem;
    padding: 15px 10px;
  }
  .contact_checkbox_container {
    margin: 3% 0;
  }
  .blur_contact_checkbox_text {
    margin-top: -5px;
    font-size: 1.24rem;
  }
  .contact_checkbox_container input[type="checkbox"] {
    width: 25px;
    height: 25px;
  }
}

@media screen and (max-width: 990px) {
  .blur_contact_form {
    padding: 6% 7%;
  }
  .blur_contact_checkbox_text {
    font-size: 0.9rem;
  }
  .blur_contact_form_field_text {
    font-size: 1.01rem;
    padding: 0.7%;
  }
}
@media screen and (max-width: 320px) {
  .blur_contact_form {
    padding: 4%;
  }
  .blur_contact_checkbox_text {
    font-size: 0.8rem;
  }
  .blur_contact_form_field_text {
    font-size: 0.91rem;
    padding: 0.7%;
  }
  .blur_contact_form_field input {
    padding: 7px;
    font-size: 0.9rem;
  }
}