* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
body,
html {
  overflow-x: hidden;
  --primary-color: #2fbf66;
  --primary-color-dark: #26a858;
  --primary-color-blue: #39a7dc;
  --primary-color-dark-blue: #166175;
  --headings-color: #696868;
  --nav-black-color: #525252;
  /* --darkHeading-color: #4d4b4b; */
  --darkHeading-color: #2fbf66;
  --var-font-family: Arial, Helvetica, sans-serif;
  --base-font-size: 16px;

  background-color: white;
  background: white;
  /* background-image: url("./Assets/workers2.png"); */
  /* background-image: url("./Assets/Pages.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed; */
}
body {
  min-height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
}
::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
article {
  flex: 1;
}

.error_msg {
  position: absolute;
  top: 4px;
  font-size: 0.9rem;
}
/* Carousel.css */

.carousel-container {
  background: url("./Assets/carusalBg.jpg") no-repeat;
  width: 100%;
  margin: 0 auto; /* Center the carousel horizontally */
  overflow: hidden; /* Hide any overflow */
}

.carousel-slide {
  /* display: flex;
  justify-content: center; 
  align-items: center; */
  height: 400px; /* Set the height of the slides */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional: Shadow effect */
}

.carousel-text {
  font-size: 24px; /* Adjust font size */
  color: #333; /* Text color */
  text-align: center; /* Center text alignment */
  padding: 20px; /* Add some padding around the text */
}

/* Carousel Home.css */

.carousel-container_1 {
  height: 100vh;
  position: relative;
  width: 100%;
  margin: 0 auto; /* Center the carousel horizontally */
  overflow: hidden; /* Hide any overflow */
}

.carousel-slide_1 {
  overflow: hidden;
  height: 100vh; /* Set the height of the slides */
  background-color: #f0f0f0; /* Optional: Background color for slides */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* Individual slide backgrounds */
.slide1 {
  background-image: url("./Assets/slide-1.jpg");
}
.slide2 {
  background-image: url("./Assets/slide-2.jpg");
}
.slide3 {
  background-image: url("./Assets/slide-3.jpg");
}

.static-content {
  width: 100%;
  position: absolute;
  top: 50%;
  /* left: 50%;
  transform: translate(-50%, -50%); */
  z-index: 1; /* Ensure the text is above the carousel */
}

.carousel-text_1 {
  display: flex;
  justify-content: center;
  font-size: 24px;
  color: #fff;
  text-align: center;
  /* padding: 20px;  
  /* background: rgba(0, 0, 0, 0.5); 
  border-radius: 10px; */
}

.carousel-slide_1::before {
  overflow: hidden;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.02);
  z-index: 1;
}
.carousel-slide_1 > * {
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 1200px) {
  .carousel-container_1 {
    height: 80vh;
  }
  .carousel-slide_1 {
    overflow: hidden;
    height: 80vh;
  }
  .carousel-slide_1::before {
    height: 80vh;
  }
}
@media screen and (max-width: 1030px) {
  .carousel-container_1 {
    height: 70vh;
  }
  .carousel-slide_1 {
    overflow: hidden;
    height: 70vh;
  }
  .carousel-slide_1::before {
    height: 70vh;
  }
}
@media screen and (max-width: 800px) {
  .carousel-container_1 {
    height: 63vh;
  }
  .carousel-slide_1 {
    overflow: hidden;
    height: 63vh;
  }
  .carousel-slide_1::before {
    height: 63vh;
  }
  .book_now_btn {
    font-size: 13px;
  }
}
@media screen and (max-width: 700px) {
  .carousel-container_1 {
    height: 31vh;
  }
  .carousel-slide_1 {
    overflow: hidden;
    background-size: contain;
    height: 31vh;
  }
  .carousel-slide_1::before {
    height: 31vh;
  }
  .static-content {
    top: 60%;
  }
}
@media screen and (max-width: 400px) {
  .carousel-container_1 {
    height: 45vh;
  }
  .carousel-slide_1 {
    overflow: hidden;
    height: 35vh;
  }
  .carousel-slide_1::before {
    height: 35vh;
  }
  .static-content {
    top: 45%;
  }
}
