.contact_form {
  padding: 8% 7%;
  /* background-color: rgb(255, 255, 255); */
  /* box-shadow: 0px 0px 17px 2px rgb(0, 0, 0, 0.1); */
  border-radius: 10px;
  position: relative;
}
.contact_form_heading {
  padding: 2% 0;
  /* color: rgb(43, 43, 43); */
}
.contact_form_field {
}
.contact_form_field_text {
  font-size: 1.1rem;
  font-weight: 500;
  padding: 1% 0px;
  color: rgb(90, 89, 89);
}
.contact_form_field input {
  padding: 10px 10px;
  border: 1px solid lightgray;
  outline: none;
  border-radius: 7px;
  margin-bottom: 5%;
  color: gray;
  font-weight: 500;
  font-size: 0.9rem;
  width: 98%;
}
.contact_form_field textarea {
  padding: 10px 10px;
  border: 1px solid lightgray;
  outline: none;
  border-radius: 7px;
  margin-bottom: 5%;
  color: gray;
  font-weight: 500;
  font-size: 0.9rem;
  width: 98%;
}
.contact_form_field textarea:focus {
  color: rgb(18, 18, 18);
  font-weight: 400;
  font-size: 0.9rem;
}
.contact_form_field input:focus {
  color: rgb(18, 18, 18);
  font-weight: 400;
  font-size: 0.9rem;
}
.contact_checkbox_container {
  display: flex;
  align-items: flex-start;
}
.contact_checkbox_container input[type="checkbox"] {
  width: 20px;
  height: 20px;
  border-radius: 7px;
  margin-right: 9px;
  font-size: 2rem;
  border: 0.25rem solid green;
  accent-color: var(--primary-color);
}
.contact_checkbox_text {
  margin-top: -2px;
  color: rgb(62, 60, 60);
  font-weight: 400;
  font-size: 1rem;
}
.contact_checkbox_container input[type="checkbox"]:checked {
  background: white;
  background-color: white;
}
.contact_form_button {
  padding: 7px 10px;
  border: 1px solid var(--primary-color);
  width: 98%;
  transition: 0.5s ease-in-out;
  border-radius: 10px;
  margin-top: 1.2rem;
  color: var(--primary-color);
  font-weight: 500;
  background-color: transparent;
}
.contact_form_button:hover {
  background-color: var(--primary-color);
  color: white;
  font-weight: 500;
}

@media screen and (max-width: 1200px) {
  .contact_form_field_text {
    font-size: 0.93rem;
  }
  .contact_form_field input {
    margin-bottom: 3.7%;
    padding: 7px 10px;
  }
  .contact_form_field textarea {
    margin-bottom: 4%;
    height: 75px;
  }
  .contact_checkbox_text {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 786px) {
  .contact_form {
    padding-bottom: 4%;
  }
  .contact_form_field_text {
    font-size: 0.93rem;
  }
  .contact_form_field input {
    margin-bottom: 3.7%;
    padding: 7px 10px;
  }
  .contact_form_field textarea {
    margin-bottom: 4%;
    height: 75px;
  }
  .contact_checkbox_text {
    font-size: 0.9rem;
  }
}
