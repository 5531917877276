.option_container {
  max-width: 380px;
  /* background-color: aliceblue; */
  /* min-width: 300px; */
  margin: 10px 0;
  display: flex;
  align-items: center;
  /* height: 25px; */
}
.option_icon_span {
  display: flex;
  align-items: flex-start;
  margin-right: 5px;
}
.option_text_span {
  display: flex;
  align-items: flex-end;
  /* height: 100%; */
}

@media (max-width: 768px) {
  .option_container {
    max-width: 300px;
  }
}
@media (max-width: 600px) {
  .option_container {
    /* width: 230px; */
    width: 42%;
    margin-right: 10px;
    max-width: 300px;
  }
  .option_text_span {
    font-size: 0.95rem;
  }
}
@media (max-width: 390px) {
  .option_container {
    /* width: 230px; */
    width: 45%;
    margin-right: 10px;
    max-width: 300px;
  }
  .option_text_span {
    font-size: 0.8rem;
  }
}
