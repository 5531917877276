.quotation_main {
  background-image: url("../../Assets/sprayimg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.about_main.quotation {
  position: relative;
  padding-bottom: 0;
  min-height: 60vh;
}
.about_content.quotation {
  margin-bottom: 120px;
  font-size: 2.6rem;
  font-weight: 700;
  font-family: var(--var-font-family);
}
.quotation_price_main_container {
  position: absolute;
  top: 85%;
  width: 100%;
  display: flex;
  justify-content: center;
}
.quotation_price_main {
  padding: 2% 1%;
  width: 40%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
}
.quotation_price_tag_line {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 1.05rem;
  margin-bottom: 4%;
}
.quotation_price_sub_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px 10px;
}
.quotation_price_sub_container_content {
  width: 85%;
}
.quotation_price_sub_container_heading {
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--primary-color);
}
.quotation_price_sub_container_text {
  font-size: 0.94rem;
  color: var(--headings-color);
}
.quotation_price_sub_container_netToatal {
  background-color: rgba(245, 245, 245, 0.826);
  font-size: 1.6rem;
  font-weight: 600;
  margin: auto;
  padding: 8px 37px;
  border-radius: 15px;
  color: var(--primary-color);
}
.Property_container h2 {
  position: relative;
  font-size: 1.81rem;
  color: var(--headings-color);
}
.discount_info {
  position: absolute;
  top: 5px;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  left: 250px;
  padding: 5px 20px;
  background-color: black;
  color: yellow;
  font-size: 0.9rem;
}
.quotation_main_container {
  background-color: white;
  padding: 5px 4%;
}
.quotation_main_container p {
  padding: 10px 0;
  padding-top: 150px;
  font-size: 1.06rem;
}

.Property_container_comps {
  display: flex;
  flex-wrap: wrap;
}
.property_info_main {
  margin: 1% 0%;
  margin-right: 2%;
  flex: 1;
  background-color: #68c8ab39;
  padding: 10px 15px;
  border-radius: 10px;
  min-width: 32%;
}
.property_info_content {
  padding: 10px 0;
  display: flex;
  align-items: center;
}
.property_info_icon {
  height: 30px;
  width: 30px;
}
.property_info_question {
  font-size: 1.2rem;
  margin-left: 10px;
}
.property_info_select {
  margin: 5px 0;
}

.inclusion_container_main_option {
  display: flex;
}
.inclusion_container_main_option.small_view {
  display: none;
}
.options_main_container {
  width: 380px;
  margin-right: 5%;
}

.inclusion_container_main {
  padding: 1% 0%;
}
.inclusion_container_main h2 {
  padding: 2% 0%;
  font-size: 1.76rem;
  color: var(--headings-color);
}
.inclusion_containers {
  display: flex;
  flex-wrap: wrap;
}
.inclucion {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 1%;
  margin-right: 4%;
  max-width: 46%;
  min-width: 350px;
}
.inclucion_icon.info:hover {
  cursor: pointer;
}
.inclucion_text {
  flex-grow: 1;
  padding: 0.5% 3%;
  font-size: 1.2rem;
  font-weight: 600;
}

.extras_main {
  margin-bottom: 3%;
}
.extras_main h2 {
  padding: 2% 0;
  font-size: 1.76rem;
  color: var(--headings-color);
}

.extras_containers {
  display: flex;
}

.extra {
  flex: 1;
  max-width: 46%;
  display: flex;
  align-items: center;
  padding: 1% 0.75%;
  margin-right: 4%;
}
.extra_icon {
  margin-right: 15px;
}
.extra img {
  margin-right: 15px;
  min-height: 34px;
  max-height: 36px;
  max-width: 33px;
}
.extra_text {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 10px 1%;
  padding-left: 2.4%;
  margin-right: 20px;
  border-radius: 10px;
  background-color: rgba(245, 245, 245, 0.653);
}
.extra_text input {
  margin-right: 15px;
  height: 20px;
  width: 20px;
}
.extra_text input:hover {
  cursor: pointer;
}
.extra_text span {
  font-size: 1.15rem;
  color: var(--headings-color);
}
.extra_select {
  flex: 1;
  overflow: hidden;
  height: 40px;
  position: relative;
  transition: 0.5s ease-in-out;
}
.extra_select span {
  position: absolute;
  font-size: 0.7rem;
  top: -17px;
  left: 5px;
}

main h2 {
  padding: 2% 0;
  padding-bottom: 1%;
  font-size: 1.81rem;
  color: var(--headings-color);
}
.date_time_main p {
  padding-top: 0px;
  font-size: 1.1rem;
}
.date_time_date_container h4 {
  color: var(--headings-color);
  font-size: 1.5rem;
}
.date_time_date_picker_container {
  display: flex;
  flex-wrap: wrap;
}
.date_time_date_picker_container p {
  padding-top: 5px;
  padding-left: 15px;
  font-size: 1.1rem;
  width: 50%;
}
.date_time_date_picker_container p span {
  margin-top: 15px;
  color: red;
  font-size: 1.15rem;
}
.date_picker_modal {
  flex: 1;
  min-width: 400px;
}
.datePicker {
  width: 100% !important;
  padding: 8px;
  border-radius: 8px;
  outline: none;
  border: 1px solid lightgray;
}
.dateErr {
  width: 100% !important;
  padding: 8px;
  border-radius: 8px;
  outline: none;
  border: 1px solid red;
}

.date_picker_modal,
.date_picker_modal > div.react-datepicker-wrapper,
.date_picker_modal
  > div
  > div.react-datepicker__input-container
  .date_picker_modal
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
}

.time_container {
  padding: 2% 0;
}
.time_container p {
  font-size: 1.17rem;
  font-weight: 600;
  padding: 0;
  color: var(--headings-color);
}
.time_container p span {
  color: red;
}
.time_comps_container {
  display: flex;
  flex-wrap: wrap;
}
.time_main {
  flex: 1;
  min-width: 330px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.4% 0;
  margin-right: 0.8%;
  border-radius: 10px;
  position: relative;
  background-color: var(--primary-color);
}
.time_main:hover {
  cursor: pointer;
}
.time_main_icon {
  color: white;
  position: absolute;
  left: 15%;
}
.time_main_time {
  padding: 16px 12px;
  color: white;
  font-size: 1.15rem;
}
.schedule_additional_questions_container {
  display: flex;
}
.property_info_main.additional_question {
  background-color: transparent;
}

.details_main p {
  padding: 0 0px;
  font-size: 1.2rem;
}
.contact_details_inputs {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.contact_details_inputs input {
  flex: 1;
  min-width: 35%;
  max-width: 40%;
  margin: 1%;
  padding: 10px 15px;
  border-radius: 10px;
  outline: none;
  border: 1px solid var(--primary-color);
  color: var(--headings-color);
  font-size: 1.1rem;
  font-weight: 500;
}

.contact_details_inputs input:focus {
  font-weight: 400;
}
.contact_details_text_area {
  flex: 1;
  min-width: 70%;
  max-width: 90%;
  margin: 1%;
  padding: 10px 15px;
  border-radius: 10px;
  outline: none;
  color: var(--darkHeading-color);
  border: 1px solid var(--primary-color);
  font-size: 1.1rem;
  font-weight: 600;
}
.contact_details_text_area {
  font-weight: 400;
}

.qoutation_pyament_btn_main {
  margin: 40px 10px;
  padding: 10px;
  text-align: center;
  position: relative;
}
.qoutation_pyament_btn_main button {
  padding: 15px 10px;
  background-color: transparent;
  color: var(--primary-color);
  outline: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  width: 40%;
  transition: 0.5s ease-in-out;
  font-weight: 600;
}
.qoutation_pyament_btn_main button:hover {
  background-color: var(--primary-color);
  color: white;
}
.buttonErrDiv {
  position: absolute;
  top: -25px;
  color: red;
  font-size: 0.9rem;
  display: flex;
  justify-content: center;
  width: 100%;
}

@media (max-width: 768px) {
  .about_main.quotation {
    min-height: 33vh;
  }
  .about_content.quotation {
    /* margin-bottom: 165px; */
    font-size: calc(var(--base-font-size) + 2.2vw);
  }
  .quotation_main_container {
    padding: 5px 2.2%;
  }
  .discount_info {
    font-size: 0.7rem;
    height: unset;
    padding: 5px 10px;
    width: fit-content;
  }
  .quotation_price_main_container {
    position: absolute;
    top: 77%;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .quotation_price_main {
    width: 60%;
  }
  .quotation_price_sub_container_heading {
    font-size: 1.48rem;
  }
  .quotation_price_sub_container_text {
    font-size: 0.82rem;
  }
  .quotation_price_sub_container_netToatal {
    font-size: 1.4rem;
  }
  .Property_container h2,
  main h2 {
    font-size: 1.67rem;
  }
  .property_info_main {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .property_info_question {
    font-size: 0.98rem;
  }
  .inclusion_container_main h2,
  .extras_main h2 {
    font-size: 1.4rem;
  }
  .extra_text span {
    font-size: 0.9rem;
  }
  .date_time_date_container h4 {
    font-size: 1.3rem;
  }
  .date_time_date_picker_container p {
    font-size: 0.97rem;
    width: 100%;
  }
  .contact_details_inputs input,
  .contact_details_text_area {
    font-size: 0.98rem;
    font-weight: 400;
  }
  .inclusion_containers {
    flex-wrap: wrap;
  }
  .inclucion {
    max-width: 100%;
  }
  .inclucion_text {
    font-size: 0.98rem;
    font-weight: 400;
  }

  .extras_containers {
    flex-direction: column;
  }
  .extra {
    max-width: 100%;
  }
}
@media (max-width: 600px) {
  .about_content.quotation {
    /* margin-bottom: 23%; */
    /* font-size: 2rem; */
    font-size: calc(var(--base-font-size) + 2.5vw);
  }
  .discount_info {
    position: unset;
    margin: 7px 0;
    font-size: 0.7rem;
    height: unset;
    padding: 5px 10px;
    width: fit-content;
  }
  .quotation_price_main_container {
    top: 77%;
  }
  .quotation_price_main {
    width: 75%;
  }
  .quotation_price_sub_container_heading {
    font-size: 1.28rem;
  }
  .quotation_price_sub_container_text {
    font-size: 0.77rem;
  }
  .quotation_price_sub_container_netToatal {
    font-size: 1.2rem;
  }
  .Property_container_comps {
    flex-wrap: wrap;
  }
  .property_info_main {
    min-width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .inclusion_container_main_option {
    display: none;
  }
  .inclusion_container_main_option.small_view {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .options_main_container {
    width: 160px;
    max-width: 270px;
    margin-right: 0%;
  }

  .extras_containers {
    flex-direction: column;
  }
  .extra {
    max-width: 100%;
  }
  .schedule_additional_questions_container {
    flex-direction: column;
  }
}

@media (max-width: 426px) {
  .about_main.quotation {
    min-height: 60vh;
  }
  .about_content.quotation {
    margin-bottom: 160px;
    /* font-size: 2rem; */
    font-size: calc(var(--base-font-size) + 2vw);
  }
  .contact_details_inputs {
    flex-direction: column;
  }
  .contact_details_inputs input,
  .contact_details_text_area {
    max-width: 95%;
    margin-top: 12px;
  }
  .qoutation_pyament_btn_main button {
    width: 80%;
    padding: 10px;
  }
}
@media (max-width: 376px) {
  .about_main.quotation {
    min-height: 50vh;
  }
  .quotation_price_main {
    width: 85%;
  }
  .quotation_price_tag_line {
    font-size: 0.96rem;
  }

  .quotation_price_sub_container_heading {
    font-size: 1rem;
  }
  .quotation_price_sub_container_text {
    font-size: 0.68rem;
  }
  .quotation_price_sub_container_netToatal {
    font-size: 1rem;
  }
  .quotation_main_container p {
    padding-top: 100px;
    font-size: 0.9rem;
  }
  .Property_container h2,
  main h2 {
    font-size: 1.37rem;
  }
  .property_info_main {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .property_info_question {
    font-size: 0.9rem;
  }
  .inclusion_container_main h2,
  .extras_main h2 {
    font-size: 1.1rem;
  }
  .extra {
    margin-right: 0;
  }
  .extra_text {
    margin-right: 5px;
  }
  .extra_select span {
    display: none;
  }
  .date_time_main p {
    padding-top: 10px;
  }
  .time_main {
    min-width: 300px;
  }
  .contact_details_inputs {
    flex-direction: column;
  }
  .date_time_date_container h4 {
    font-size: 1rem;
  }
  .date_picker_modal {
    min-width: 170px;
  }
  .details_main p {
    padding-top: 10px;
  }
  .contact_details_inputs input,
  .contact_details_text_area {
    max-width: 95%;
    margin-top: 12px;
  }
  .inclucion {
    min-width: 230px;
  }
  .inclucion_text {
    font-size: 0.9rem;
  }
  .qoutation_pyament_btn_main button {
    width: 80%;
    padding: 10px;
  }
}

@media (max-width: 311px) {
  .time_main {
    min-width: 280px;
  }
}
@media (max-width: 295px) {
  .about_main.quotation {
    min-height: 57vh;
  }

  .time_main {
    min-width: 270px;
  }
}
