.about_main.payment {
  min-height: 40vh;
}
.payment_main_container {
  background-color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 5%;
}
.payment_main_container h3 {
  margin-bottom: 3%;
  display: flex;
  justify-content: space-between;
}
.payment_main_container h3 span {
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--headings-color);
  /* text-decoration: underline; */
}
.total_view {
  border-bottom: 1px solid lightgray;
}
.discount_amount {
  margin-left: 7px;
}
.text_line_through {
  text-decoration-line: line-through;
}
.total_small_view {
  display: none;
}
.back_arrow {
  margin-bottom: 30px;
  color: gray;
}
.back_arrow:hover {
  cursor: pointer;
}
.booking_details {
  display: flex;
  justify-content: space-between;
  /* flex-direction: column; */
  flex-wrap: wrap;
  padding: 10px 20px;
}
.booking_data {
  margin: 15px;
  margin-bottom: 5px;
  border-bottom: 1px solid lightgray;
  display: flex;
  justify-content: space-between;
  width: 40%;
  padding: 5px;
}
.booking_data_question {
  font-weight: 600;
  font-size: 1.13rem;
  color: var(--headings-color);
}
.booking_data_selection {
  font-size: 1.04rem;
  margin-left: 10px;
  text-align: end;
}
.extras_booking_details_main h4 {
  padding-top: 15px;
}
.privacy_terms_statement {
  margin-top: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.privacy_terms_statement span {
  margin-bottom: 5px;
  cursor: pointer;
  color: blue;
}
.card_main_container {
  margin: 5%;
  margin-bottom: 1%;
}
#booking-form {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  max-width: 45%;
  margin: 0 auto;
  padding: 20px;
  /* border: 1px solid #ccc; */
  border-radius: 10px;
  box-shadow: 0 0 5px 7px whitesmoke;
}

#booking-form h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}
.total-amount,
.gst-grand-total,
.grand-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  font-size: 1.1rem;
  font-weight: 600;
  margin-top: 5px;
  padding: 0 5px;
  border-bottom: 1px solid lightgray;
}
.card_selection_heading {
  font-size: 1.04rem;
}
.card_info_error {
  color: red;
  font-size: 0.8rem;
  margin-left: 15px;
}
.card_number_container {
  background-color: rgba(245, 245, 245, 0.595);
  border: 1px solid #e5e3e3;
  border-radius: 5px;
  padding: 10px 7px;
  margin-bottom: 5px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.cards_img {
  margin-bottom: 25px;
}
.card_number_span {
  display: flex;
  flex-wrap: nowrap;
}
#card-number {
  margin-left: 10px;
  font-size: 1.06rem;
}
.card_number_span input {
  background-color: transparent;
  outline: none;
  border: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}
#expiry-date {
  width: 50px;
  text-align: center;
}
#cvc {
  margin-left: 10px;
  width: 50px;
  text-align: center;
}

.card-types {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
#mastercard {
  margin-right: 50px;
  margin-left: 10px;
  height: 15px;
  width: 15px;
}
#visa {
  margin-top: 1px;
  height: 15px;
  width: 15px;
  margin-left: 10px;
}
.statement {
  display: flex;
  align-items: flex-start;
  margin: 10px 0;
}
.statement input {
  height: 18px;
  width: 18px;
  border-radius: 5px;
  margin-top: 3px;
}
.statement p {
  margin-left: 10px;
  font-size: 0.98rem;
}
button[type="submit"] {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
  background-color: #45a049;
}
.card_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 10px;
  margin-top: 5%;
}
.sub_card_container {
  width: 60%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.pay_btn {
  /* background-color: #4caf50; */
  background-color: #4780e4;
  color: white;
  padding: 10px 20px;
  margin: 28px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-self: center;
  width: 70%;
  font-weight: 600;
  position: relative;
}
.pay_btn:hover {
  background-color: #3176ec;
}
.payment_form {
  display: flex;
  flex-direction: column;
}
.card_error_message {
  position: absolute;
  top: 52px;
  left: 0;
  width: 100%;
  text-align: center;
  color: red;
  font-weight: 400;
  font-size: 0.89rem;
}
@media (max-width: 1254px) {
  #booking-form {
    max-width: 65%;
  }
}
@media (max-width: 993px) {
  #booking-form {
    max-width: 75%;
  }
}
@media (max-width: 770px) {
  .payment_main_container h3 {
    font-size: 1.3rem;
  }
  .payment_main_container h3 span {
    font-size: 1.1rem;
  }
  .sub_card_container {
    width: 100%;
  }
  #booking-form {
    max-width: 90%;
  }
  #card-number {
    margin-left: 5px;
    font-size: 0.96rem;
    width: 150px;
  }
  #expiry-date {
    width: 40px;
    text-align: center;
  }

  #cvc {
    font-size: 0.96rem;
    margin-left: 0px;
    width: 45px;
    text-align: center;
  }
}
@media (max-width: 650px) {
  .payment_main_container h3 span {
    font-size: 0.95rem;
  }
  .booking_data_question {
    font-size: 1rem;
  }
  .booking_data_selection {
    font-size: 0.95rem;
  }
}
@media (max-width: 500px) {
  .payment_main_container {
    padding: 5% 1%;
  }
  .booking_details {
    flex-direction: column;
  }
  .payment_main_container h3 {
    display: flex;
    font-size: 1.2rem;
    justify-content: space-between;
    /* flex-direction: column; */
  }
  .payment_main_container h3 span {
    font-size: 1rem;
    margin: 0;
    display: none;
  }
  .total_small_view {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 1rem;
    font-weight: 600;
    color: var(--headings-color);
    min-width: 110px;
  }
  .total_small_view_price {
  }
  .total_small_view_gst {
    padding-bottom: 5px;
    border-bottom: 1px solid lightgray;
    width: 100%;
  }
  .total_small_view_total_price {
    text-decoration-line: line-through;
  }
  .discount_amount_small_view {
    display: block;
  }
  .booking_data {
    width: 90%;
  }
  .booking_data_question {
    font-size: 1rem;
  }
  .booking_data_selection {
    font-size: 0.95rem;
  }
  .card_main_container {
    display: flex;
    justify-content: center;
    margin: 0%;
  }
  #booking-form {
    max-width: 96%;
    font-size: 0.96rem;
  }
  #cvc {
    text-align: left;
    margin-left: 0;
    font-size: 0.96rem;
  }
  #card-number {
    margin-left: 5px;
    font-size: 0.96rem;
    overflow-x: hidden;
  }
}
@media (max-width: 376px) {
  .sub_card_container {
    padding: 5px;
  }
  .payment_main_container {
    padding: 5% 1%;
  }
  .booking_details {
    flex-direction: column;
  }
  .booking_data {
    width: 90%;
  }
  .booking_data_question {
    font-size: 1rem;
  }
  .booking_data_selection {
    font-size: 0.95rem;
  }
  .card_main_container {
    text-align: center;
    margin: 0%;
  }

  #booking-form {
    max-width: auto;
    width: 90%;
    font-size: 0.96rem;
    margin: 0 0;
    padding: 13px;
  }
  #booking-form h2 {
    font-size: 1.3rem;
    margin-bottom: 10px;
  }
  .total-amount,
  .gst-grand-total,
  .grand-total {
    margin-bottom: 20px;
    font-size: 0.95rem;
    padding: 0 2px;
  }
  .card_selection_heading {
    font-size: 0.9rem;
  }
  .card_number_container {
    padding: 10px 3px;
    max-width: 100%;
  }
  .cards_img {
    margin-bottom: 25px;
  }
  .card_number_span {
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
  }
  #card-number {
    margin-left: 5px;
    font-size: 0.96rem;
    width: 100px;
  }
  .card_number_span input {
    background-color: transparent;
    outline: none;
    border: none;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    display: none;
  }
  #expiry-date {
    width: 30px;
    text-align: center;
  }

  #cvc {
    font-size: 0.96rem;
    margin-left: 0px;
    width: 35px;
    text-align: center;
  }

  .statement p {
    margin-left: 10px;
    font-size: 0.8rem;
  }
  button[type="submit"] {
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
}
