.modal {
  display: flex;
  z-index: 110;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}
.modal_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 120;
  min-width: 30%;
  min-height: 37%;
  border-radius: 10px;
  border: 1px solid lightgray;
  background-color: rgba(255, 255, 255, 0.86);
  padding: 10px;
  padding-bottom: 15px;
  margin-top: 60px;
}
.modal_container.termsCondtions {
  display: block;
  text-align: center;
  max-width: 650px;
}
.modal_terms_list {
  line-height: 1.7;
  margin-top: 15px;
  margin-right: 10px;
  text-align: justify;
}
.modal_terms_list ol li {
  margin-top: 7px;
}
.modal_container.form {
  /* background-color: rgb(0, 0, 0, 0.5); */
}
.icon_cross {
  position: absolute;
  right: 0;
  top: 0;
  padding: 3px;
}
.icon_cross:hover {
  cursor: pointer;
}
.modal_contact_form_cont {
  padding-top: 20px;
}

.modal_container.list {
  max-width: 57%;
  padding: 1.5% 1.8%;
}
.modal_list_heading {
  font-size: 1.6rem;
  padding-right: 25px;
  padding-bottom: 10px;
  color: var(--primary-color);
}
.modal_list_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.modal_list_item {
  display: flex;
  align-items: center;
  min-width: 48%;
  padding: 10px 1px;
}
.modal_check_icon {
  margin-right: 5px;
}
.modal_list_text {
  font-size: 0.98rem;
}

@media screen and (max-width: 1200px) {
  .modal_container {
    margin-top: 170px;
    padding: 10px 20px;
  }
}
@media screen and (max-width: 426px) {
  .modal_container {
    padding: 10px 15px;
    margin: 0 3%;
    margin-top: 170px;
  }
}
