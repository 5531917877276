.about_main {
  position: relative; /* Ensure the pseudo-element is positioned relative to .about_main */
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  /* background-image: url("../../Assets/Pages.jpg"); */
  background-image: url("../../Assets/team.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.about_main::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Shading effect */
  z-index: 1; /* Place the shading layer above the background image */
}

.about_main > * {
  position: relative; /* Ensure the content of out_main is above the shading layer */
  z-index: 2;
}

.about_content {
  color: white;
  max-width: 48%;
  font-size: 2.7rem;
  font-weight: 600;
}
.our_story {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.line_about {
  position: relative;
  height: 1px;
  width: 70%;
  border-bottom: 2px solid gray;
}
.bottom_div_booking.about {
  border-bottom: none;
  padding-bottom: 0%;
}
.line_box_about {
  position: absolute;
  height: 17px;
  width: 17px;
  background-color: var(--primary-color);
  top: -7px;
  left: 50%;
}

.bottom_div_booking_content_title_line.about {
  font-size: 1.39rem;
  font-weight: 700;
}

.bottom_div_booking_pic.center {
  align-self: center;
  width: 45%;
  overflow: hidden;
  border-radius: 7px;
}
.bottom_div_booking_content.about {
  width: 46%;
  border-radius: 5px;
  background-color: #e7e7e767;
}
.bottom_div_booking_content.about p {
  font-size: 1.12rem;
  color: var(--darkHeading-color);
}
.our_story h3 {
  text-align: center;
  padding: 3%;
  font-weight: 500;
}
.quality_container {
  width: 100%;
  background-color: white;
  padding: 0% 8%;
  margin-bottom: 3%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.quality_card {
  width: 370px;
  min-height: 123px;
  margin: 4% 1%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.quality_card:hover {
  transform: scale(1.01);
}
.qc_heading {
  color: var(--darkHeading-color);
  font-size: 1.7rem;
  font-weight: 400;
  margin-bottom: 15px;
}
.qc_description {
  font-size: 1.1rem;
  font-weight: 400;
  color: var(--headings-color);
}
.home_faq_container.about {
  background-color: white;
  /* background-color: #0000005d; */
}
@media screen and (min-width: 1600px) {
  .about_content {
    font-size: 3rem;
  }
  .about_main {
    padding-bottom: 70px;
  }
}
@media screen and (min-width: 1200px) {
  .about_content {
    font-size: 2.3rem;
  }
  .about_main {
    padding-bottom: 40px;
  }
}
@media screen and (max-width: 1200px) {
  .about_content {
    font-size: 2.3rem;
    max-width: fit-content;
  }
  .about_main {
    padding-bottom: 40px;
  }
}
@media only screen and (max-width: 990px) {
  .about_content {
    font-size: 1.7rem;
  }
  .about_main {
    padding-bottom: 20px;
  }
  .bottom_div_booking_content.about {
    padding: 5% 3%;
  }
  .bottom_div_booking_content_title_line.about {
    font-size: 1.29rem;
    font-weight: 700;
  }
  .bottom_div_booking_content.about p {
    font-size: 1rem;
  }
  .quality_container {
    padding: 0% 3%;
  }
  .quality_card {
    width: 330px;
    margin: 4% 2%;
  }
  .qc_heading {
    font-size: 1.53rem;
  }
  .qc_description {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 768px) {
  .about_content {
    font-size: 1.4rem;
  }
  .about_main {
    padding-bottom: 20px;
    min-height: 30vh;
    background-position: left;
  }
  .bottom_div_booking_content.about {
    padding: 5% 3%;
  }
  .bottom_div_booking_content_title_line.about {
    font-size: 1.19rem;
    font-weight: 700;
  }
  .bottom_div_booking_content.about p {
    font-size: 1rem;
  }
  .our_story h3 {
    font-size: 1.37rem;
  }
  .quality_container {
    padding: 0% 3%;
  }
  .quality_card {
    width: 280px;
    margin: 4% 2%;
  }
  .qc_heading {
    font-size: 1.33rem;
  }
  .qc_description {
    font-size: 0.96rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 426px) {
  .about_main {
    padding-bottom: 10px;
    min-height: 48vh;
  }
  .about_content {
    font-size: 1.1rem;
  }
  .bottom_div_booking_pic.center {
    width: 80%;
    height: 45vh;
  }
  .bottom_div_booking_content.about {
    padding: 5% 3%;
    width: auto;
    text-align: justify;
  }
  .bottom_div_booking_content_title_line.about {
    font-size: 1.09rem;
    font-weight: 600;
  }
  .bottom_div_booking_content.about p {
    font-size: 1rem;
  }
  .our_story h3 {
    font-size: 1.3rem;
    padding: 2%;
  }
  .quality_container {
    padding: 0% 4%;
  }
  .quality_card {
    width: 350px;
    margin: 4% 2%;
  }
  .qc_heading {
    font-size: 1.25rem;
  }
  .qc_description {
    font-size: 0.93rem;
  }
}
@media only screen and (max-width: 620px) {
  .bottom_div_booking {
    flex-direction: column-reverse;
    margin-bottom: 8%;
  }
  .bottom_div_booking_pic.center {
    width: 97%;
    height: 34vh;
  }
  .bottom_div_booking_content.about {
    padding: 5% 3%;
    width: auto;
    text-align: justify;
  }
  .bottom_div_booking_content_title_line.about {
    font-size: 1.09rem;
    font-weight: 600;
  }
  .bottom_div_booking_content.about p {
    font-size: 1rem;
  }
  .our_story h3 {
    font-size: 1.3rem;
    padding: 2%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 320px) {
  .about_content {
    font-size: 1rem;
    text-align: center;
    padding: 1% 2%;
  }
  .our_story h3 {
    font-size: 1.15rem;
    padding: 2%;
    margin-top: 3%;
  }
  .quality_container {
    padding: 0% 4%;
  }
  .quality_card {
    width: 270px;
    margin: 4% 2%;
  }
  .qc_heading {
    font-size: 1.1rem;
  }
  .qc_description {
    font-size: 0.83rem;
  }
  .bottom_div_booking_pic.center {
    width: 85%;
    height: 30vh;
  }
}
