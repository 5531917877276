.banner-container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  display: flex;
  align-items: center;
  height: 35px;
}

.banner-text {
  display: inline-block;
  white-space: nowrap;
  animation: scroll linear infinite;
  display: flex;
  gap: 30px;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.banner-item {
  display: inline-block;
  padding: 0 125px; /* Adjust spacing between texts as needed */
  white-space: nowrap;
  font-weight: 700;
  font-size: 0.9rem;
}
@media (max-width: 460px) {
  .banner-container {
    height: 25px;
  }
  .banner-text {
    gap: 25px;
  }
  .banner-item {
    padding: 0 105px;
    font-size: 0.75rem;
  }
}
