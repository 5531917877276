.input__box__label {
  color: white;
  font-feature-settings: "salt" on;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  margin-bottom: 0.5em;
}
.Add_Client_Image_container.file {
  width: fit-content;
  padding-right: 10px;
}
.Add_Client_Image_container {
  width: 160px;
  height: 50px;
  overflow: hidden;
  border-radius: 5px;
  margin-top: 3px;
  border: 1px solid lightgray;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: whitesmoke;
}
.file_exist {
}
.changeimage_label {
  height: 100%;
  width: 100%;
  cursor: pointer;
  padding-right: 20px;
}
.formuploadimage_input {
  height: 0;
  width: 0;
}

.images_cross {
  position: absolute;
  top: 2px;
  right: 2px;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: #000000c9;
  color: white;
}
.images_cross:hover {
  color: rgb(250, 16, 16);
}
.add_image_style {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
}
.AddimageBtn {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}
.cam_Image_upload {
  font-size: 0.9rem;
  font-weight: 600;
  color: rgb(48, 48, 48);
}
@media screen and (max-width: 768px) {
  .changeimage_label {
    padding-right: 0px;
  }
}
