.qa_main {
  padding: 10px 20px;
  background-color: white;
  width: 77%;
  border-top: 1px solid lightgray;
  /* border-bottom: 1px solid lightgray; */
  display: flex;
  flex-direction: column;
  max-height: 55px;
  overflow: hidden;
  justify-content: space-between;
  transition: 0.5s ease-in-out;
}
.qa_main.open {
  max-height: 1000px;
}
.qa_main:hover {
  cursor: pointer;
}
.qa_main title {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.qa_main title span {
  font-size: 1.04rem;
  color: var(--headings-color);
}
.qa_answer {
  padding: 9px 10px;
  padding-right: 15px;
  font-size: 0.9rem;
  /* padding-top: 18px; */
  /* transition: 0.5s ease-in-out; */
  /* margin-top: 15px; */
}

@media only screen and (max-width: 990px) {
  .qa_main {
    width: 85%;
  }
  .qa_main title span {
    font-size: 0.9rem;
    padding-bottom: 10px;
  }
  .qa_answer {
    font-size: 0.85rem;
  }
}
@media only screen and (max-width: 768px) {
  .qa_main {
    width: 90%;
    padding: 10px 14px;
  }
  .qa_main title span {
    font-size: 0.83rem;
    padding-bottom: 10px;
  }
  .qa_answer {
    font-size: 0.76rem;
  }
}
@media only screen and (max-width: 426px) {
  .qa_main {
    width: 90%;
    padding: 10px 14px;
  }
  .qa_main title span {
    font-size: 0.8rem;
    padding-bottom: 10px;
  }
  .qa_answer {
    font-size: 0.74rem;
  }
}
