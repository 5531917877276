.payment_success {
  height: 76vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.629);
}
.payment_success span {
  background-color: white;
  height: 28vh;
  width: 25vw;
  padding: 0 30px;
  border-radius: 15px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
}
.congras_text {
  position: absolute;
  top: 15px;
  color: var(--primary-color);
  font-size: 1.6rem;
  font-weight: 700;
}
