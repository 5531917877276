.ContactUs_main.career {
  background-image: url("../../Assets/team.jpg");
  /* background-image: url("../../Assets/glassClean.jpg"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-color: white; */
}
.ContactUs_container.career {
  background-color: rgba(0, 0, 0, 0.48);
  /* background-color: black; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 190px 10px;
}
.blur_contact_form.contactUs.career {
  width: 60%;
}
.check_box_container {
  display: flex;
  width: 99%;
  justify-content: space-between;
  margin-bottom: 4%;
}

.career_selection_option {
  margin-right: 8px;
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.blur_contact_form_field.contactUs.career {
  position: relative;
}
.blur_contact_form_field_text.contactUs.career {
  font-size: 1.2rem;
}
.career_selection_option {
  color: Black;
}
.radio_input {
  background-color: red;
  width: 100%;
}
.error_msg {
  position: absolute;
  color: red;
  font-size: 0.8rem;
  right: 20px;
  top: 30px;
}

@media (max-width: 900px) {
  .blur_contact_form.contactUs.career {
    width: 80%;
  }
}
@media (max-width: 768px) {
  .blur_contact_form.contactUs.career {
    width: 90%;
  }
}
@media (max-width: 600px) {
  .check_box_container {
    flex-direction: column;
  }
  .career_selection_option {
    width: 100%;
  }
  .blur_contact_form.contactUs.career {
    width: 96%;
  }
  .blur_contact_form_field_text.contactUs.career {
    font-size: 0.9rem;
  }
}
@media (max-width: 340px) {
  .blur_contact_form.contactUs.career {
    width: 99%;
  }
}
