@media only screen and (min-width: 768px) {
  .services_main_line_container {
    padding: 1% 15%;
    text-align: center;
    background-color: white;
  }
  .services_main_line_container p {
    text-align: center;
    font-size: 1.1rem;
    font-weight: 400;
  }
  .cards_container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 8%;
    padding-bottom: 4%;
    background-color: white;
  }
  .icon_card_services {
    display: flex;
    flex: 1;
    /* width: 100%; */
    min-height: 350px;
    /* max-width: 300px; */
    min-width: 280px;
    height: auto;
    flex-direction: column;
    margin: 2%;
    margin-top: 3%;
    border-radius: 5px;
    transition: 0.5s ease-in-out;
    border-bottom: none;
    padding: 20px;
    box-shadow: 0 0 15px 15px whitesmoke;
    position: relative;
    overflow: "hidden";
    /* background-color: red; */
  }
  .icon_card_services:hover {
    transform: scale(1.02);
    cursor: pointer;
  }
  .icon_card_services_image {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 98%;
    height: 70%;
    object-fit: cover;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  .icon_card_services_content_span {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 7px 10px;
    height: 30%;
    /* background-color: #15151583; */
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .icon_card_services:hover .icon_card_services_content_span {
    background-color: var(--primary-color);
    color: white;
  }
  .icon_card_icon.services {
    height: 55px;
    width: 55px;
  }
  .icon_card_icon.services img {
    height: 55px;
    width: 55px;
    object-fit: contain;
  }
  .icon_card.services strong {
    font-size: 1.5rem;
  }
  .icon_card_description {
    font-size: 12px;
  }
  .icon_card_description.services {
    padding: 1% 0;
    background-color: transparent;
  }
  .icon_card_services_arrow {
    position: absolute;
    top: 5px;
    right: 10px;
    width: 0;
    overflow: hidden;
    /* color: #2585e4; */
    color: var(--primary-color);
    transition: 0.3s ease-in-out;
    border-radius: 10%;
  }
  .icon_card_services:hover .icon_card_services_arrow {
    padding: 7px;
    width: 45px;
  }
}
.services_why_us_main {
  width: 100%;
  min-height: 50vh;
  display: flex;
  padding-bottom: 2%;
  margin: 0% 2%;
  justify-content: center;
  align-items: center;
  background-color: white;
  /* background: #0aa043ad; */
}
.review_form {
  display: flex;
  flex-direction: column;
  width: 60%;
}
.rating_photo_container {
  display: flex;
  align-items: center;
  margin: 2% 0;
}
.photo_container {
  height: 180px;
  width: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  object-fit: cover;
  margin-right: 20px;
  border: 1px solid lightgray;
}
.photo_container:hover {
  cursor: pointer;
}
.photo_container input {
  height: 0;
  width: 0;
  display: none;
}
.ratings {
  margin: auto;
}
.review_inputs {
  padding: 15px;
  border-radius: 10px;
  outline: none;
  border: 1px solid lightgray;
  margin: 2% 0;
  max-width: 80%;
}
.submit_button {
  padding: 15px;
  width: 30%;
  border-radius: 10px;
  border: 1px solid var(--primary-color);
  background-color: transparent;
  font-weight: 700;
  color: var(--primary-color);
}
.submit_button:hover {
  cursor: pointer;
  color: white;
  background-color: var(--primary-color);
}
@media only screen and (max-width: 1200px) {
}
@media only screen and (max-width: 992px) {
  .icon_card_services {
    min-width: 280px;
  }
}

@media only screen and (max-width: 768px) {
  /* Assuming styles.css */
  .review_form {
    width: 80%;
  }
  .review_inputs {
    max-width: 90%;
  }

  .services_main_line_container {
    padding: 10% 3%;
    text-align: center;
    background-color: white;
  }
  .services_main_line_container p {
    text-align: center;
    font-size: 1.1rem;
    font-weight: 400;
  }
  .cards_container {
    width: 100%;
    /* padding: 0 2%; */
    padding-bottom: 2%;
    background-color: white;
    padding: 7% 7%;
  }
  .icon_card_services {
    display: flex;
    width: 100%;
    height: 340px;
    flex-direction: column;
    margin: 2%;
    margin-top: 3%;
    border-radius: 5px;
    transition: 0.5s ease-in-out;
    border-bottom: none;
    padding: 20px;
    position: relative;
    overflow: hidden;
  }
  .icon_card_services:hover {
    box-shadow: 0 0 15px 15px whitesmoke;

    transform: scale(1.02);
    cursor: pointer;
  }
  .icon_card_services_image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  .icon_card_services_content_span {
    padding: 7px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
  }
  .icon_card_services:hover .icon_card_services_content_span {
    background-color: var(--primary-color);
    color: white;
  }
  .icon_card_description {
    text-align: center;
  }
  .icon_card_description.services {
    padding: 1% 0;
    background-color: transparent;
    font-size: 12px;
  }
  .icon_card_services_arrow {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 0;
    overflow: hidden;
    color: var(--primary-color);
    transition: 0.3s ease-in-out;
    border-radius: 10%;
  }
  .icon_card_services:hover .icon_card_services_arrow {
    padding: 7px;
    width: 45px;
  }
  .services_main_line_container {
    padding: 15px;
  }
  .icon_card_services {
    min-width: 240px;
  }
}
@media only screen and (max-width: 570px) {
  .review_form {
    width: 90%;
  }
  .review_inputs {
    max-width: 96%;
  }
  .icon_card_services {
    max-width: 95%;
  }
  .submit_button {
    width: 50%;
    margin-top: 15px;
  }
}
@media only screen and (max-width: 427px) {
  .review_form {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1%;
  }
  .review_inputs {
    max-width: 100%;
    width: 100%;
  }
  .cards_container {
    padding: 7% 1%;
  }
  .rating_photo_container {
    flex-direction: column;
  }
  .photo_container {
    margin-bottom: 15px;
    margin-right: 0;
  }
  .ratings {
    margin: 17px 0;
  }
  .submit_button {
    width: 70%;
  }
  .icon_card_services {
    max-width: 100%;
  }
}

button.slick-prev:before,
button.slick-next:before {
  color: black !important;
}
