.blogs_main_container {
  min-height: 60vh;
  margin-top: 100px;
  padding: 4% 5%;
  background-color: white;
}
.blogs_sub_main_container {
  max-width: 70%;
}
.blogs_sub_main_container h1 {
  margin-bottom: 3%;
}
.blogs_sub_main_container p {
}
.blogs_sub_main_container img {
  height: 50vh;
  width: 80%;
  border-radius: 7px;
  overflow: hidden;
  margin: 5% 0;
}

.detail_post_read_btn {
  padding: 11px 30px;
  text-align: center;
  cursor: pointer;
  width: 140px;
  font-size: 14px;
  line-height: 25px;
  font-weight: 600;
  height: auto;
  background-color: #2fbf66;
  color: #fff;
  border-radius: 23.5px;
  border: 0;
  vertical-align: top;
  text-decoration: none;
  text-transform: capitalize;
  position: relative;
  overflow: hidden;
  font-family: "Poppins", sans-serif;
}
