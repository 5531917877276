.icon_card {
  display: flex;
  flex-direction: column;
  min-height: 216px;
  max-height: 300px;
  width: 270px;
  padding-bottom: 10px;
  margin: 1%;
  margin-top: 3%;
  border-radius: 5px;
  border-bottom: 1px solid black;
  transition: 0.7s ease-in-out;
}
.icon_card:hover {
  transform: scale(1.14);
  z-index: 5;
  background-color: white;
  box-shadow: 0 0 18px 5px rgba(223, 222, 222, 0.5);
  transition: 0.7s ease-in-out;
}
.icon_card:hover span {
  transition: 0.8s ease-in-out;
  transform: scale(0.9);
}
.icon_card_icon {
  height: 50px;
  width: 50px;
  object-fit: cover;
  margin: 2%;
  margin-bottom: 15px;
  overflow: hidden;
}
.icon_card strong {
  font-size: 1.3rem;
  font-weight: 500;
  padding: 2%;
  padding-bottom: 1%;
  color: var(--darkHeadings-color);
}
.icon_card_description {
  margin-top: 3px;
  background-color: rgba(245, 245, 245, 0.184);
  width: 100%;
  padding: 3%;
  border-radius: 5px;
  font-size: 1rem;
  text-align: start;
}

/* simple card styles */

.offer_card {
  color: white;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  border-radius: 10px;
  height: fit-content;
  max-width: 290px;
  max-height: 400px;
  padding: 6px;
  margin: 20px;
  transition: 0.8s ease-in-out;
}
.offer_card:hover {
  transform: scale(1.14);
  z-index: 5;
  box-shadow: 0 0 18px 5px #dfdede80;
  transition: 0.7s ease-in-out;
}
.offer_card_top {
  display: flex;
}
.offer_card_top_index {
  color: white;
  font-size: 2.59rem;
  font-weight: 600;
}
.offer_card_top strong {
  font-size: 1.49rem;
  font-weight: 600;
  margin-left: 12px;
  align-self: flex-end;
}
.offer_card_para {
  font-size: 1.1rem;
  padding-top: 30px;
  padding-bottom: 15px;
}

/* rating cards  */

.rating_card {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-around; */
  justify-content: space-between;
  width: 300px;
  /* height: 420px; */
  min-height: 370px;
  overflow: hidden;
  max-height: 420px;
  align-self: stretch;
  padding: 20px;
  margin: 8px 15px;
  border-radius: 20px;
  transition: 0.5s ease-in-out;
  background-color: white;
  box-shadow: 0 0 20px 10px #dfdede83;
}
.custom-quote {
  font-size: 1em;
  font-style: italic;
  line-height: normal;
  margin-bottom: 14px;
}

.custom-quote::before,
.custom-quote::after {
  font-size: 2em;
}

.custom-quote::before {
  content: open-quote;
}

.custom-quote::after {
  content: close-quote;
}
.rating_card:hover {
  transform: scale(1.07);
  /* background-color: var(--primary-color); */
  /* color: white; */
  overflow: hidden;
  z-index: 5;
  box-shadow: 0 0 10px 3px rgba(223, 222, 222, 0.6);
}
.rating_card img {
  border-radius: 100%;
  height: 85px;
  width: 85px;
}
.rating_card_name {
  font-weight: 600;
  font-size: 1.37rem;
}
.rating_card_stars {
}
.rating_card_text {
  /* position: relative; */
}
.rating_card_read_more {
  color: rgb(82, 82, 220);
  text-align: right;
  font-weight: 600;
  font-size: 0.95rem;
}
.rating_card_read_more:hover {
  cursor: pointer;
}
.margin {
  margin: 8px 0;
}

@media screen and (max-width: 1200px) {
  .icon_card {
    width: 220px;
    padding-bottom: 5px;
    margin-bottom: 4%;
  }
  .icon_card strong {
    font-size: 1.16rem;
    padding: 1%;
  }
  .icon_card_description {
    padding: 2%;
    font-size: 0.97rem;
  }
  .offer_card {
    margin: 10px;
    width: 295px;
  }
  .rating_card {
    width: 290px;
  }
}
@media screen and (max-width: 990px) {
  .icon_card {
    width: 275px;
    padding-bottom: 5px;
    margin-bottom: 40px;
  }
  .icon_card strong {
    font-size: 1.16rem;
    padding: 1%;
  }
  .icon_card_description {
    padding: 2%;
    font-size: 0.97rem;
  }
  .offer_card {
    margin: 7px;
    width: 282px;
  }
  .rating_card {
    width: 290px;
  }
}
@media screen and (max-width: 768px) {
  .icon_card {
    width: 240px;
    padding-bottom: 5px;
    margin-bottom: 40px;
  }
  .icon_card strong {
    font-size: 1.13rem;
    padding: 1.2%;
  }
  .icon_card_description {
    padding: 2%;
    font-size: 0.95rem;
  }
  .offer_card {
    margin: 7px;
    width: 230px;
    padding: 4px;
    margin: 7px 4px;
  }
  .rating_card {
    width: 240px;
    min-height: 320px;
    max-height: 500px;
  }
}
@media screen and (max-width: 426px) {
  .icon_card {
    width: 275px;
    padding-bottom: 5px;
    margin-bottom: 25px;
    min-height: 200px;
  }
  .icon_card strong {
    font-size: 1.16rem;
    padding: 1%;
  }
  .icon_card_description {
    padding: 2%;
    font-size: 0.97rem;
  }
  .offer_card {
    margin: 7px;
    width: 295px;
  }

  .offer_card_top_index {
    font-size: 1.87rem;
  }
  .offer_card_top strong {
    font-size: 1.5rem;
  }
  .offer_card_para {
    font-size: 0.99rem;
    padding-top: 16px;
    padding-bottom: 12px;
  }
  .rating_card {
    width: 310px;
    min-height: 320px;
    max-height: 420px;
  }
}
@media screen and (max-width: 320px) {
  .offer_card {
    margin: 7px;
    width: 252px;
  }
  .offer_card_top_index {
    font-size: 1.38rem;
  }
  .offer_card_top strong {
    font-size: 1.33rem;
  }
  .offer_card_para {
    font-size: 0.97rem;
    padding-top: 10px;
    padding-bottom: 7px;
  }
  .rating_card {
    width: 310px;
    min-height: 320px;
    max-height: 510px;
    overflow: hidden;
  }
  .rating_card:hover {
    transform: scale(1.09);
  }
}
