.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  position: fixed;
  width: 100%;
  background-color: white;
  transition: 0.5s ease-in-out;
  z-index: 100;
}
.navbar-header {
  height: 70px;
}
.navbar-toggler {
  display: none;
  cursor: pointer;
  border: none;
  outline: none;
}
.navbar_main {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 12px 25px;
}
.navbar-brand img {
  height: 90px;
  padding: 0;
  /* width: 195px; */
  object-fit: contain;
}
.request_qoute_btn {
  background-color: #3974d9;
  padding: 7px 15px;
  font-size: 0.9rem;
  color: white;
  border-radius: 30px;
  display: flex;
  align-items: center;
  gap: 5px;
  text-decoration: none;
}
.request_qoute_btn.call_btn {
  border-radius: 100%;
  padding: 7px;
}

.navbar-toggler span {
  display: block;
  width: 25px;
  height: 3px;
  background-color: gray;
  margin: 5px 0;
}
.navbar_book_info.navbar_book_info_small_view {
  display: none;
}
.navbar_small_device_view {
  display: none;
}
.navbar_info_container {
  width: 50%;
}
.navbar_book_info {
  display: flex;
  justify-content: end;
  padding: 5px 5px;
  padding-bottom: 12px;
}
.navbar_number_container {
  margin: 0 5px;
  display: flex;
  width: 30%;
  min-width: 175px;
  cursor: pointer;
  color: var(--headings-color);
  transition: 0.2s ease-in-out;
  text-decoration: none;
}
.navbar_number_container:hover {
  color: var(--primary-color);
}
.navbar_number_container_icon {
  padding: 7px;
  border: 1px solid lightgray;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border: 1px solid var(--primary-color);
  /* color: whitesmoke; */
}
.navbar_number_container:hover .navbar_number_container_icon {
  border: 1px solid var(--primary-color);
}
.navbar_number_container_txt {
  margin-left: 8px;
}
.navbar_number_container_txt1 {
  font-size: 0.87rem;
  font-weight: 700;
  color: var(--headings-color);
}
.navbar_number_container_txt2 {
  font-size: 1.2rem;
  font-weight: 300;
  transition: 0.2s ease-in-out;
}
.navbar_number_container:hover .navbar_number_container_txt2 {
  color: var(--primary-color);
}
.book_info {
  padding: 0;
  transition: 0.2s ease-in-out;
  text-decoration: none;
}

.navbar-links {
  width: 100%;
  transition: 0.5s ease-in-out;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.nav-link {
  color: var(--headings-color);
  text-decoration: none;
  padding: 10px 5px;
  padding-bottom: 3px;
}
.nav-link:hover {
  transition: 0.5s ease-in-out;
  color: var(--primary-color);
}
@media (max-width: 920px) {
  .navbar_info_container {
    width: 60%;
  }
}
/* Media query for small screens */
@media (max-width: 768px) {
  .navbar_book_info.navbar_book_info_small_view {
    display: flex;
    justify-content: right;
    gap: 10px;
  }
  .navbar_small_device_view {
    display: flex;
  }
  .hide_on_small_width {
    display: none;
  }
  .navbar-toggler {
    display: block;
  }
  .navbar {
    /* background-color: #000000cf; */
    color: black;
    flex-direction: column;
    padding: 0px;
    padding-top: 0px;
    padding-left: 0px;
    height: 155px;
    overflow: hidden;
  }

  .navbar-brand img {
    height: 70px;
    margin-left: 20%;
    margin-bottom: 5%;
  }
  .navbar.open {
    height: 490px;
  }
  .navbar_main {
    padding: 0px;
  }
  .navbar_book_info {
    justify-content: center;
    padding: 0 5px;
  }

  .navbar_number_container {
    margin: 0 5px;
    height: 40px;
    width: fit-content;
    display: flex;
    align-items: center;
    /* border: 1px solid whitesmoke; */
    border: 1px solid lightgray;
    border-radius: 7px;
    cursor: pointer;
    /* color: whitesmoke; */
    transition: 0.2s ease-in-out;
  }
  .navbar_number_container_icon {
    padding: 0;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .navbar_number_container_txt {
    margin-left: 3px;
    font-size: 0.9rem;
  }
  .navbar_number_container:hover .navbar_number_container_icon {
    border: none;
  }

  .navbar_main_small_view {
    width: 100vw;
    display: flex;
    flex-direction: column-reverse;
    height: 300px;
    justify-content: flex-end;
    padding: 5px 12px;
  }
  .navbar-header {
    height: 70px;
    display: flex;
    /* width: 100vw; */
    justify-content: space-between;
  }
  .navbar-links {
    display: flex;
    flex-direction: column;
    position: absolute;
    /* top: 90px; */
    top: 160px;
    left: 0;
    width: 100%;
    /* background-color: black;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    padding-bottom: 15px;
  }
  .navbar-links.open {
    top: 130px;
  }

  .navbar-links .nav-link {
    padding-top: 15px;
  }
}

@media (max-width: 500px) {
  .navbar {
    height: 120px;
  }
  .navbar-brand img {
    margin-left: 3%;
    height: 60px;
    /* width: 130px; */
  }
  .navbar_number_container {
    min-width: 135px;
    overflow: hidden;
    height: 30px;
    justify-content: center;
  }
  .navbar_number_container_txt {
    font-size: 0.8rem;
  }
  .navbar_number_container_icon {
    height: 30px;
    width: 30px;
    padding-right: 4px;
  }
  .request_qoute_btn {
    padding: 3px 10px;
  }
}

@media (max-width: 376px) {
  .navbar-brand img {
    height: 50px;
    margin-bottom: 1%;
  }
}
@media (max-width: 340px) {
  .request_qoute_btn {
    font-size: 13px;
  }
}
@media (max-width: 311px) {
  .navbar.open {
    height: 440px;
  }
  .request_qoute_btn {
    font-size: 10px;
  }
}
@media (max-width: 295px) {
  /* .navbar_book_info.navbar_book_info_small_view {
    flex-direction: column;
    align-items: center;
  } */
  .navbar_number_container {
    margin-bottom: 5px;
  }
  .navbar-links.open {
    top: 131px;
  }
  .request_qoute_btn {
    font-size: 8px;
  }
}
