.home {
  /* padding-bottom: 4%; */
  /* background-image: url("../../Assets/workers2.png");
  background-repeat: no-repeat;
  background-size: cover; */
}
.home_main {
  background: rgba(0, 0, 0, 0.5);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding: 100px 200px; */
  align-items: center;
  padding: 140px 8%;
  padding-bottom: 1%;
  position: relative;
}
.discount_advertisment_container {
  position: absolute;
  top: 150px;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.766);
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  font-weight: 600;
}
.home_main_content {
  /* background-color: #41b86f86; */
  color: white;
  max-width: 76%;
  padding: 2% 5%;
  display: flex;
  padding-bottom: 2%;
  margin-bottom: 2%;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 2px;
}
.home_main_content h1 {
  margin-bottom: 5%;
  margin-top: 5%;
  font-weight: 800;
  text-align: center;
}
.home_main_content p {
  color: white;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.book_now_btn {
  /* width: 20%; */
  background-color: "transparent";
  border: 2px solid var(--primary-color);
  padding: 5px 10px;
  border-radius: 10px;
  margin-top: 0px;
  text-align: center;
  color: white;
  font-weight: 600;
  cursor: pointer;
}
.book_now_btn:hover {
  background-color: var(--primary-color);
  /* background-color: var(--primary-color-dark); */
}
.home_main_contact {
  width: 43%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: flex-end; */
  margin-top: 25px;
  padding: 1% 5%;
  height: 100%;
  padding-bottom: 0;
  /* background-color: antiquewhite; */
  border-radius: 15px;
}
.home_main_contact_container {
  /* background-color: rgba(245, 245, 245, 0.725); */
  /* width: 100%; */
}
.home_main_contact p {
  font-size: 1.2rem;
  font-weight: 600;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: rgb(59, 59, 59); */
  color: white;
}

/* home page services container  */
.home_services_heading {
  /* color: var(--darkHeading-color); */
  color: var(--primary-color);
  padding: 0% 8%;
  padding-top: 3%;
  margin: 0;
  background-color: white;
}

/* why us container  */
.whyUs_main {
  background-color: white;
  padding: 5% 8%;
  min-height: 98vh;
}
.whyUs_main_container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.whyUs_heading {
  color: var(--darkHeading-color);
}
.whyUs_heading_under_text {
  margin-bottom: 3%;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 1.1rem;
}
.whyUs_sub_container {
  display: flex;
  justify-content: space-between;
}
.whyUs_sub_container_cards {
  width: 50%;
  margin-right: 1%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 3%;
}

.why_us_right_container {
  width: 45%;
}
.why_us_right_container_title_line {
  color: var(--primary-color);
  font-size: 1.3rem;
  font-weight: 600;
}
.why_us_right_container_title_sub_line {
  font-size: 1.7rem;
  font-weight: 600;
  padding: 2.6% 0;
}
.why_us_right_container p {
  margin-top: 2%;
  margin-bottom: 7%;
  width: 90%;
  text-align: justify;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.why_us_right_container_note {
  display: flex;
  align-items: flex-start;
  width: 90%;
}
.why_us_right_container_note_txt {
  font-weight: 700;
  margin-top: 11px;
  margin-left: 7px;
  font-size: 1.1rem;
}
.why_us_right_container_btn {
  justify-self: flex-end;
  padding: 10px 15px;
  margin-top: 8%;
  width: 40%;
  margin-left: 20px;
  transition: 0.5s ease-in-out;
  border-radius: 10px;
  outline: none;
  font-weight: 600;
  color: var(--primary-color);
  background-color: transparent;
  border: 1px solid var(--primary-color);
}
.why_us_right_container_btn:hover {
  background-color: var(--primary-color);
  color: white;
}

/* offers styling */

.offers_main {
  width: 100%;
  background-color: var(--primary-color);
  min-height: 90vh;
  display: flex;
  justify-content: space-around;
  padding: 50px 5%;
}
.offers_main_left_content {
  background-color: white;
  width: 42%;
  padding: 4% 5%;
  display: flex;
  border-radius: 22px;
  flex-direction: column;
  justify-content: space-around;
}
.offers_main_left_content h2 {
  line-height: 140%;
}
.offers_main_left_content p {
  font-size: 1.16rem;
  /* text-align: justify; */
}
.offers_main_left_content_link {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 1.1rem;
  display: flex;
  transition: 0.5s ease-in-out;
}
.offers_main_left_content_link:hover {
  cursor: pointer;
}
.offers_main_left_content_link_icon {
  margin-left: 10px;
  transition: 0.5s ease-in-out;
}
.offers_main_cards_container {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  max-width: 50%;
}
.offers_main_cards {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

/* booking bttom div  */

.bottom_div_booking {
  width: 80%;
  display: flex;
  justify-content: space-between;
  padding: 5% 0%;
  min-height: 80vh;
  border-bottom: 2px solid gray;
  position: relative;
  margin-bottom: 5%;
}
.bottom_div_booking_pic {
  width: 47%;
  height: 55vh;
  overflow: hidden;
  border-radius: 7px;
  /* align-self: center; */
}
.bottom_div_booking_pic img {
  width: 100%;
  height: 100%;
}
.bottom_div_booking_content {
  width: 45%;
  padding: 3%;
}
.bottom_div_booking_content_title_line {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 1.17rem;
}
.bottom_div_booking_content_title_sub_line {
  color: var(--darkHeadings-color);
  font-size: 1.88rem;
  font-weight: 660;
  font-family: Arial, Helvetica, sans-serif;
  margin: 2.5% 0%;
}
.steps_text {
  font-size: 1.15rem;
  color: var(--darkHeadings-color);
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  margin: 2% 0;
}
.bottom_div_booking_content__btn {
  padding: 9px 17px;
  width: 140px;
  border-radius: 10px;
  margin-top: 9%;
  outline: none;
  font-weight: 700;
  color: white;
  transition: 0.5s ease-in-out;
  /* background-color: var(--primary-color); */
  color: var(--primary-color);
  background-color: transparent;
  border: 1px solid var(--primary-color);
  outline: none;
  /* border: none; */
}
.bottom_div_booking_content__btn:hover {
  transform: scale(1.12);
  color: white;
  background-color: #41b86e;
}
.line_box {
  position: absolute;
  height: 17px;
  width: 17px;
  background-color: var(--primary-color);
  top: 98.7%;
  left: 50%;
}

/* clients container */
.bottom_div {
  background-color: #effaf3;
  padding: 1%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bottom_div_clients {
  width: 100%;
  padding: 3%;
  margin-bottom: 4%;
  text-align: center;
  background: linear-gradient(
    to bottom,
    var(--primary-color) 50%,
    transparent 50%
  );
}
.bottom_div_clients strong {
  font-size: 2rem;
  font-weight: 700;
  font-family: var(--var-font-family);
  color: white;
}
.bottom_div_clients_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  /* justify-content: center; */
  margin-top: 100px;
}
.home_faq_container {
  /* background-color: #effaf3; */
  background-color: white;
  padding: 1%;
  padding-bottom: 7%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home_faq_container strong {
  padding: 2% 0;
  padding-bottom: 2%;
  font-size: 1.81rem;
  color: var(--darkHeading-color);
  font-weight: 600;
}

@media screen and (min-width: 1600px) {
  .home_main_content h1 {
    font-size: 2.7rem;
  }
  .home_main_content p {
    font-size: 1.47rem;
  }
  .home_main_contact {
    width: 35%;
    padding: 3% 5%;
  }
  .home_main_contact p {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 1200px) {
  .home_main {
    padding: 140px 4%;
  }
  .home_main_contact {
    padding: 2%;
  }
  .whyUs_main {
    padding: 5% 4.5%;
  }
  .whyUs_heading_under_text {
    font-size: 0.95rem;
    margin-top: 2%;
  }
  .whyUs_sub_container_cards {
    width: 55%;
  }
  .why_us_right_container {
    width: 40%;
  }
  .why_us_right_container_title_sub_line {
    font-size: 1.4rem;
  }
  .why_us_right_container_note_txt {
    font-size: 1rem;
  }
  .offers_main {
    padding: 50px 6%;
    flex-direction: column;
  }
  .offers_main_left_content {
    padding: 4.1% 5.7%;
    margin: 3% 0;
    width: auto;
    height: fit-content;
    justify-content: flex-start;
  }
  .offers_main_left_content h2 {
    font-size: 1.47rem;
  }
  .offers_main_left_content p {
    font-size: 1.06rem;
    margin: 24px 0;
  }
  .offers_main_left_content_link {
    font-size: 1.02rem;
    margin: 20px 0;
  }
  .offers_main_cards_container {
    max-width: 100%;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 3%;
  }
  .bottom_div_booking {
    width: 90%;
  }
  .bottom_div_booking_pic {
    align-self: center;
  }
  .bottom_div_booking_content {
    width: 50%;
    padding: 5%;
  }
  .bottom_div_booking_content_title_line {
    font-size: 1.05rem;
  }
  .bottom_div_booking_content_title_sub_line {
    font-size: 1.4rem;
    margin: 7% 0;
  }
  .steps_text {
    font-size: 0.98rem;
    font-weight: 500;
  }
  .bottom_div_clients_container {
    margin-top: 50px;
  }
  .bottom_div_clients {
    padding: 6% 2%;
  }
  .bottom_div_clients strong {
    font-size: 1.65rem;
    margin-top: 6%;
  }
}
@media only screen and (max-width: 990px) {
  .home_main {
    padding: 140px 4%;
  }
  .home_main_content h1 {
    font-size: 1.95rem;
    font-weight: 700;
  }
  .home_main_content p {
    font-size: 1.1rem;
  }
  .home_main_contact {
    padding: 0.2%;
    width: 48%;
  }
  .whyUs_main {
    padding: 5% 4%;
  }
  .whyUs_heading_under_text {
    font-size: 0.95rem;
    margin-top: 2%;
  }
  .whyUs_sub_container {
    flex-direction: column;
  }
  .whyUs_sub_container_cards {
    width: auto;
  }
  .why_us_right_container {
    width: auto;
  }
  .why_us_right_container_title_line {
    font-size: 1.14rem;
    font-weight: 600;
  }
  .why_us_right_container_title_sub_line {
    font-size: 1.38rem;
    font-weight: 600;
  }
  .why_us_right_container_note_txt {
    font-size: 1rem;
  }
  .offers_main {
    padding: 50px 5.5%;
    flex-direction: column;
  }
  .offers_main_left_content {
    padding: 4.1% 5%;
    margin: 3% 0;
    width: auto;
    height: fit-content;
    justify-content: flex-start;
  }
  .offers_main_left_content h2 {
    font-size: 1.3rem;
  }
  .offers_main_left_content p {
    font-size: 0.99rem;
    margin: 24px 0;
  }
  .offers_main_left_content_link {
    font-size: 0.9rem;
    margin: 20px 0;
  }
  .offers_main_cards_container {
    max-width: 100%;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 3%;
  }
  .bottom_div_booking {
    width: 95%;
  }
  .bottom_div_booking_pic {
    align-self: center;
  }
  .bottom_div_booking_content {
    width: 50%;
    padding: 5%;
  }
  .bottom_div_booking_content_title_line {
    font-size: 0.97rem;
  }
  .bottom_div_booking_content_title_sub_line {
    font-size: 1.3rem;
    margin: 7% 0;
  }
  .steps_text {
    font-size: 0.9rem;
    font-weight: 500;
  }
  .bottom_div_clients_container {
    margin-top: 40px;
  }
  .bottom_div_clients {
    padding: 6% 2%;
  }
  .bottom_div_clients strong {
    font-size: 1.65rem;
    margin-top: 6%;
  }
}
@media only screen and (max-width: 768px) {
  .book_now_btn {
    padding: 4px 8px;
    font-size: 10px;
  }
  .discount_advertisment_container {
    top: 131px;
    font-size: 0.9rem;
    height: 25px;
  }
  .discount_advertisment_container_span2 {
    display: none;
  }
  .home_main {
    padding: 140px 4%;
  }
  .home_main_content {
    max-width: 85%;
  }
  .home_main_content h1 {
    font-size: 1.95rem;
    font-weight: 700;
  }
  .home_main_content p {
    font-size: 1.1rem;
  }
  .home_main_contact {
    padding: 0.2%;
    width: 48%;
  }
  .whyUs_main {
    padding: 5% 4%;
  }
  .whyUs_heading_under_text {
    font-size: 0.95rem;
    margin-top: 2%;
  }
  .whyUs_sub_container {
    flex-direction: column;
  }
  .whyUs_sub_container_cards {
    width: auto;
  }
  .why_us_right_container {
    width: auto;
  }
  .why_us_right_container_title_line {
    font-size: 1.14rem;
    font-weight: 600;
  }
  .why_us_right_container_title_sub_line {
    font-size: 1.38rem;
    font-weight: 600;
  }
  .why_us_right_container_note_txt {
    font-size: 1rem;
  }
  .offers_main {
    padding: 50px 5.5%;
    flex-direction: column;
  }
  .offers_main_left_content {
    padding: 4.1% 5%;
    margin: 3% 0;
    width: auto;
    height: fit-content;
    justify-content: flex-start;
  }
  .offers_main_left_content h2 {
    font-size: 1.3rem;
  }
  .offers_main_left_content p {
    font-size: 0.99rem;
    margin: 24px 0;
  }
  .offers_main_left_content_link {
    font-size: 0.9rem;
    margin: 20px 0;
  }
  .offers_main_cards_container {
    max-width: 100%;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 3%;
  }
  .bottom_div_booking {
    width: 95%;
  }
  .bottom_div_booking_pic {
    align-self: center;
    width: 85%;
  }
  .bottom_div_booking_content {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5%;
  }
  .bottom_div_booking_content_title_line {
    font-size: 1.05rem;
    text-align: center;
  }
  .bottom_div_booking_content_title_sub_line {
    font-size: 1.4rem;
    text-align: center;
    margin: 7% 0;
  }
  .steps_text {
    font-size: 0.9rem;
    font-weight: 500;
    text-align: center;
  }
  .bottom_div_booking_content__btn {
    display: none;
  }
  .bottom_div_clients_container {
    margin-top: 40px;
  }
  .bottom_div_clients {
    padding: 6% 2%;
  }
  .bottom_div_clients strong {
    font-size: 1.45rem;
    margin-top: 3%;
  }
}

@media screen and (max-width: 580px) {
  .home_main {
    flex-direction: column;
    padding: 140px 3%;
    padding-bottom: 60px;
  }
  .home_main_content {
    max-width: 100%;
    padding-bottom: 2%;
  }
  .home_main_content h1 {
    font-size: 1.7rem;
  }
  .home_main_content p {
    font-size: 1.02rem;
  }
  .home_main_contact {
    width: 80%;
    align-self: center;
    padding: 1% 4%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */

@media only screen and (max-width: 426px) {
  .discount_advertisment_container {
    top: 131px;
    font-size: 0.8rem;
    height: 25px;
  }
  .home_main {
    flex-direction: column;
    padding-bottom: 60px;
  }
  .home_main_content {
    max-width: 100%;
    padding-bottom: 2%;
  }
  .home_main_content h1 {
    font-size: 1.7rem;
  }
  .home_main_content p {
    font-size: 0.9rem;
  }
  .book_now_btn {
    width: 30%;
    padding: 4px 8px;
    font-size: 10px;
  }
  .home_main_contact {
    width: auto;
    padding: 1% 4%;
  }
  .whyUs_main {
    padding: 5% 6%;
  }
  .whyUs_heading_under_text {
    font-size: 0.89rem;
  }
  .why_us_right_container_title_sub_line {
    font-size: 1.27rem;
  }
  .why_us_right_container p {
    text-align: left;
    font-size: 0.9rem;
  }
  .why_us_right_container_note_txt {
    font-size: 0.94rem;
  }
  .why_us_right_container_btn {
    width: 35%;
    padding: 7px 14px;
  }
  .bottom_div_booking {
    flex-direction: column-reverse;
  }
  .bottom_div_booking_pic {
    align-self: center;
    width: 90%;
    height: 34vh;
    margin: 7% 0;
  }
  .bottom_div_booking_content {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5%;
  }
  .bottom_div_booking_content_title_line {
    font-size: 1.05rem;
    text-align: center;
  }
  .bottom_div_booking_content_title_sub_line {
    font-size: 1.4rem;
    text-align: center;
    margin: 7% 0;
  }
  .steps_text {
    font-size: 0.9rem;
    font-weight: 500;
    text-align: center;
  }
  .bottom_div_booking_content__btn {
    display: none;
  }
  .bottom_div_clients strong {
    font-size: 1.4rem;
  }
  .bottom_div_clients {
    margin-top: 30px;
  }
  .bottom_div_clients_container {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 386px) {
  .discount_advertisment_container {
    top: 131px;
    font-size: 0.65rem;
    height: 25px;
    text-align: center;
    line-height: 1;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 320px) {
  .why_us_right_container_btn {
    width: 60%;
    padding: 5px 7px;
  }
  .offers_main_left_content {
    padding: 4% 3%;
    border-radius: 12px;
  }
  .offers_main_left_content h2 {
    font-size: 1.19rem;
  }
  .offers_main_left_content p {
    font-size: 0.89rem;
    margin: 13px 0;
  }
  .offers_main_left_content_link {
    margin: 10px 5px;
  }
  .offers_main_cards_container {
    padding: 3%;
  }
  .bottom_div_booking_content_title_line {
    font-size: 0.9rem;
  }
  .bottom_div_booking_content_title_sub_line {
    font-size: 1.28rem;
  }
  .steps_text {
    font-size: 0.88rem;
  }
  .bottom_div_booking_pic {
    height: 34vh;
    width: 90%;
  }
  .bottom_div_clients strong {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 350px) {

  .book_now_btn {
    padding: 3px 8px;
    font-size: 8px;
  }
}