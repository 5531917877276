.ContactUs_main {
  background-image: url("../../Assets/sprayimg.jpg");

  background-repeat: no-repeat;
  background-size: cover;
}
.ContactUs_container {
  color: white;
  background-color: rgba(0, 0, 0, 0.48);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 190px 10px;
}
.blur_contact_form.contactUs {
  width: 55%;
  padding: 2% 3.3%;
  box-shadow: 0px 0px 18px 15px rgba(0, 0, 0, 0.12);
}
.contact_form_heading_main {
  border-bottom: 1px solid white;
  text-align: center;
  width: 95%;
  padding: 0 10px;
  padding-bottom: 12px;
  margin: auto;
  margin-bottom: 30px;
}
.contact_form_heading {
  font-size: 2.3rem;
  font-weight: 700;
  font-family: var(--var-font-family);
  text-align: center;
  /* text-decoration: underline; */
}
.blur_contact_form.contactUs strong {
  font-family: var(--var-font-family);
  font-weight: 400;
  font-size: 1.1rem;
}
.blur_contact_form_field_text.contactUs {
  font-size: 1.34rem;
  margin-bottom: 5px;
}
.blur_contact_form_field.contactUs {
  position: relative;
}
.blur_contact_form_field.contactUs input {
  padding: 15px 12px;
  font-weight: 500;
  font-family: var(--var-font-family);
  font-size: 1rem;

  position: relative;
}
.blur_contact_form_field.contactUs textarea {
  padding: 15px 12px;
  font-weight: 500;
  font-family: var(--var-font-family);
  font-size: 1rem;
}
.contact_checkbox_container.contactUs {
  margin: 20px 0;
}
.blur_contact_checkbox_text.contactUs {
  margin-top: -2px;
  font-weight: 500;
  font-family: var(--var-font-family);
  font-size: 1.14rem;
}
.contact_checkbox_container.contactUs input[type="checkbox"]:hover {
  cursor: pointer;
}
.blur_contact_form_button.contactUs {
  padding: 17px 10px;
  border: 1px solid var(--primary-color);
  width: 98%;
  transition: 0.5s ease-in-out;
  border-radius: 10px;
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: 500;
  background-color: #43c475;
}
.blur_contact_form_button.contactUs:hover {
  background-color: #2eb762;
}

.phone_err {
  color: rgb(216, 5, 5);
  position: absolute;
  bottom: -2px;
  font-size: 0.78rem;
  left: 5px;
  font-weight: 600;
}
.phone_err.contactUs {
  color: rgb(239, 8, 8);
  position: absolute;
  bottom: 10px;
  left: 5px;
  font-weight: 600;
}

@media (max-width: 990px) {
  .blur_contact_form.contactUs {
    width: 90%;
  }
}
@media (max-width: 990px) {
  .blur_contact_form.contactUs {
    width: 90%;
  }
}
@media (max-width: 550px) {
  .blur_contact_form.contactUs {
    width: 96%;
  }
  .contact_form_heading_main {
    padding: 0 5px;
    width: 98%;
  }
  .contact_form_heading {
    font-size: 1.8rem;
  }
  .blur_contact_form.contactUs strong {
    font-size: 0.9rem;
  }
  .blur_contact_form_field_text.contactUs {
    font-size: 0.99rem;
  }
  .blur_contact_form_field.contactUs input {
    font-size: 0.94rem;
    padding: 9px 10px;
  }
  .blur_contact_checkbox_text.contactUs {
    font-size: 0.94rem;
  }
  .blur_contact_form_button.contactUs {
    padding: 13px;
  }
}